import React from "react";
import mockupimg from "../../assets/Images/Mockup.png";

function RideBookingSection() {
  return (
    <section className="mt-5 container">
      <div className="row">
        <div className="col-md-1"></div>

        <div className="col-md-9">
          <h2 className="display-4 fw-bold text-center text-dark">
            Get a ride at your Fingertips!
          </h2>
          <p className="mt-2 text-center text-secondary lead font-weight-normal">
            Select destination, confirm booking, connect with drivers, enjoy the
            trip
          </p>
          <div className="mt-5 row">
            <div className="col-md-7 d-flex justify-content-center align-items-center">
              {/* <div className="rounded-circle border border-primary p-3 d-flex justify-content-center"> */}
              <div className=" p-3 d-flex justify-content-center">
                <div
                //   className="rounded-circle border border-primary"
                //   style={{ height: "500px", width: "500px" }}
                />

                <img src={mockupimg} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-5  ">
              <div className="text-dark">
                <h3 className="h2 fw-bold">RideSafe with Ride24</h3>
                <p className="text-start mt-3 ">
                  Download our app and enjoy the best service from{" "}
                  <strong>Ride24</strong>. All you have to do to begin using it
                  is to enter your name and mobile phone number. You'll access:
                </p>
                <ul className="mt-4 list-dotted text-start fw-bold">
                  <li className=" mb-2">Special offers</li>
                  <li className=" mb-2">Ride24 loyalty program</li>
                  <li className=" mb-2">More pricing plans</li>
                </ul>
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/39f75d20669fb696ab9bb0cb8f8d3046beef44a196dc0ef917726f94edf5b983?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
                  alt="App store badges"
                  className="img-fluid mt-5"
                  style={{ maxWidth: "300px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-1"></div>
      </div>
    </section>
  );
}

export default RideBookingSection;
