import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DriverDocumentForm from "../Onboarding/DriverDocumentForm";
import ProgressStepper from "../Onboarding/ProgressStepper";
import RegisterForm from "../Onboarding/RegisterForm"

const steps = ["Register", "Owner/Driver Documents", "Vehicle Documents"];

const schema = yup.object().shape({
  fullName: yup.string().required("Full Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  mobileNumber: yup.string().required("Mobile number is required"),
  city: yup.string().required("City is required"),
  address: yup.string().required("Address is required"),

  // Add more validations based on the form fields
});

function Onboarding() {
  const [activeStep, setActiveStep] = useState(0);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    if (activeStep === steps.length - 1) {
      alert("All steps completed - form submitted");
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  // const progressPercentage = Math.round(
  //   ((activeStep + 1) / steps.length) * 100
  // );

  return (
    <div className="container-fluid">
      <div className="container mt-4">
        <div className="row">
          <div className="col-12">
            <ProgressStepper activeStep={activeStep} steps={steps} />{" "}
            {/* Use the ProgressStepper here */}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="needs-validation"
              noValidate
            >
              <div className="row">
                <div className="col-lg-8">
                  <div className="form-step">
                    {activeStep === 0 && (
                      <RegisterForm control={control} errors={errors} />
                    )}
                    {activeStep === 1 && (
                      <DriverDocumentForm control={control} errors={errors} />
                    )}
                    {/* Additional steps can be added here */}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-flex justify-content-around mt-3">
                    {activeStep > 0 && (
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleBack}
                      >
                        Back
                      </button>
                    )}
                    <button type="submit" className="btn btn-primary">
                      {activeStep === steps.length - 1 ? "Submit" : "Next"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Onboarding;

// import React, { useState } from "react";
// import { useForm, Controller } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import {
//   Container,
//   Box,
//   Button,
//   Stepper,
//   Step,
//   StepLabel,
// } from "@mui/material";

// import Header from "../Component/Header";
// import Footer from "../Component/Footer";
// import RegisterForm from "../Component/RegisterForm";
// import DocumentForm from "../Component/DocumentForm";

// const steps = ["Register", "Owner/Driver Documents", "Vehicle Documents"];

// const schema = yup.object().shape({
//   fullName: yup.string().required("Full Name is required"),
//   email: yup.string().email("Invalid email").required("Email is required"),
//   mobileNumber: yup.string().required("Mobile number is required"),
//   city: yup.string().required("City is required"),
//   // Add more validations based on the form fields
// });

// function Home() {
//   const [activeStep, setActiveStep] = useState(0);
//   const {
//     control,
//     handleSubmit,
//     formState: { errors },
//   } = useForm({
//     resolver: yupResolver(schema),
//   });

//   const onSubmit = (data) => {
//     console.log(data);
//     if (activeStep === steps.length - 1) {
//       // Submit final form
//       alert("All steps completed - form submitted");
//     } else {
//       setActiveStep((prev) => prev + 1);
//     }
//   };

//   const handleBack = () => {
//     setActiveStep((prev) => prev - 1);
//   };
//   return (
//     <div>
//       <Container>
//         <Header />
//         <Box sx={{ width: "100%" }}>
//           <Stepper activeStep={activeStep}>
//             {steps.map((label, index) => (
//               <Step key={index}>
//                 <StepLabel>{label}</StepLabel>
//               </Step>
//             ))}
//           </Stepper>

//           <form onSubmit={handleSubmit(onSubmit)}>
//             {activeStep === 0 && (
//               <RegisterForm control={control} errors={errors} />
//             )}
//             {activeStep === 1 && (
//               <DocumentForm control={control} errors={errors} />
//             )}
//             {/* Add more steps if needed */}
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 marginTop: "20px",
//               }}
//             >
//               {activeStep > 0 && <Button onClick={handleBack}>Back</Button>}
//               <Button type="submit">
//                 {activeStep === steps.length - 1 ? "Submit" : "Next"}
//               </Button>
//             </Box>
//           </form>
//         </Box>
//         <Footer />
//       </Container>
//     </div>
//   );
// }

// export default Home;

// import React, { useState } from "react";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { Container, Button, ProgressBar } from "react-bootstrap";
// import Header from "../Component/Header";
// import Footer from "../Component/Footer";
// import RegisterForm from "../Component/RegisterForm";
// import DocumentForm from "../Component/DocumentForm";

// const steps = ["Register", "Owner/Driver Documents", "Vehicle Documents"];

// const schema = yup.object().shape({
//   fullName: yup.string().required("Full Name is required"),
//   email: yup.string().email("Invalid email").required("Email is required"),
//   mobileNumber: yup.string().required("Mobile number is required"),
//   city: yup.string().required("City is required"),
//   // Add more validations for other fields
// });

// function Home() {
//   const [activeStep, setActiveStep] = useState(0);
//   const {
//     handleSubmit,
//     register,
//     formState: { errors },
//   } = useForm({
//     resolver: yupResolver(schema),
//   });

//   const onSubmit = (data) => {
//     console.log(data);
//     if (activeStep === steps.length - 1) {
//       alert("Form submitted successfully!");
//     } else {
//       setActiveStep((prevStep) => prevStep + 1);
//     }
//   };

//   const handleBack = () => {
//     setActiveStep((prevStep) => prevStep - 1);
//   };

//   const progressPercentage = (activeStep / (steps.length - 1)) * 100;

//   return (
//     <Container>
//       <Header />
//       <div className="progress-container my-4">
//         <ProgressBar
//           now={progressPercentage}
//           label={`${Math.round(progressPercentage)}%`}
//         />
//       </div>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         {activeStep === 0 && (
//           <RegisterForm register={register} errors={errors} />
//         )}
//         {activeStep === 1 && (
//           <DocumentForm register={register} errors={errors} />
//         )}
//         {/* Add more steps as necessary */}

//         <div className="d-flex justify-content-between mt-4">
//           {activeStep > 0 && (
//             <Button variant="secondary" onClick={handleBack}>
//               Back
//             </Button>
//           )}
//           <Button variant="primary" type="submit">
//             {activeStep === steps.length - 1 ? "Submit" : "Next"}
//           </Button>
//         </div>
//       </form>
//       <Footer />
//     </Container>
//   );
// }

// export default Home;
