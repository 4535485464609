import React from "react";
import { Link } from "react-router-dom"; // Import Link for routing
import Header from "../../Components/Header/Headers";
import Footer from "../../Components/Footer/Footer";
import { HeroSection } from "../../Components/HeroSection/HeroSection";
import Section1 from "../../Components/LandingSection/Section1";
import RideBookingSection from "../../Components/LandingSection/RideBookingSection";
import FleetSection from "../../Components/LandingSection/FleetSection";
import FeatureSection from "../../Components/LandingSection/FeatureSection";
import RideBookingSectionReverse from "../../Components/LandingSection/RideBookingSectionReverse";
import TestimonialSection from "../../Components/LandingSection/TestimonialSection";
import NewsAndEvents from "../../Components/LandingSection/NewsAndEvents";
import BlogSection from "../../Components/LandingSection/BlogSection";
import FAQSection from "../../Components/LandingSection/FAQSection";
import HeroEndSection from "../../Components/LandingSection/HeroEndSection";

const Landing = () => {
  return (
    <div className="landing-page">
      <Header />
      <HeroSection />
      <Section1 />
      <RideBookingSection />
      <FleetSection />
      <FeatureSection />
      <RideBookingSectionReverse />
      <TestimonialSection />
      <NewsAndEvents />
      <BlogSection />
      <FAQSection />
      <HeroEndSection />
      <Footer />
    </div>
  );
};

export default Landing;
