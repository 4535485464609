import React from "react";
import { TextField, Grid } from "@mui/material";
import { Controller } from "react-hook-form";

const RegisterForm = ({ control, errors }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Controller
          name="fullName"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Full Name"
              variant="outlined"
              fullWidth
              error={!!errors.fullName}
              helperText={errors.fullName ? errors.fullName.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Email"
              variant="outlined"
              fullWidth
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="mobileNumber"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Mobile Number"
              variant="outlined"
              fullWidth
              error={!!errors.mobileNumber}
              helperText={
                errors.mobileNumber ? errors.mobileNumber.message : ""
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="address"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Address"
              variant="outlined"
              fullWidth
              error={!!errors.address}
              helperText={errors.address ? errors.address.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="city"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="City"
              variant="outlined"
              fullWidth
              error={!!errors.city}
              helperText={errors.city ? errors.city.message : ""}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default RegisterForm;

// import React from "react";
// import { Form, Row, Col } from "react-bootstrap";

// const RegisterForm = ({ register, errors }) => {
//   return (
//     <Row className="mb-3">
//       <Col md={6}>
//         <Form.Group controlId="fullName">
//           <Form.Label>Full Name</Form.Label>
//           <Form.Control
//             type="text"
//             placeholder="Enter Full Name"
//             {...register("fullName")}
//             isInvalid={!!errors.fullName}
//           />
//           <Form.Control.Feedback type="invalid">
//             {errors.fullName?.message}
//           </Form.Control.Feedback>
//         </Form.Group>
//       </Col>

//       <Col md={6}>
//         <Form.Group controlId="email">
//           <Form.Label>Email</Form.Label>
//           <Form.Control
//             type="email"
//             placeholder="Enter Email"
//             {...register("email")}
//             isInvalid={!!errors.email}
//           />
//           <Form.Control.Feedback type="invalid">
//             {errors.email?.message}
//           </Form.Control.Feedback>
//         </Form.Group>
//       </Col>

//       <Col md={6}>
//         <Form.Group controlId="mobileNumber">
//           <Form.Label>Mobile Number</Form.Label>
//           <Form.Control
//             type="text"
//             placeholder="Enter Mobile Number"
//             {...register("mobileNumber")}
//             isInvalid={!!errors.mobileNumber}
//           />
//           <Form.Control.Feedback type="invalid">
//             {errors.mobileNumber?.message}
//           </Form.Control.Feedback>
//         </Form.Group>
//       </Col>

//       <Col md={6}>
//         <Form.Group controlId="city">
//           <Form.Label>City</Form.Label>
//           <Form.Control
//             type="text"
//             placeholder="Enter City"
//             {...register("city")}
//             isInvalid={!!errors.city}
//           />
//           <Form.Control.Feedback type="invalid">
//             {errors.city?.message}
//           </Form.Control.Feedback>
//         </Form.Group>
//       </Col>
//     </Row>
//   );
// };

// export default RegisterForm;
