import React from "react";
import { Grid, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const DriverDocumentForm = ({ control, errors }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Controller
          name="document1"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Document 1"
              variant="outlined"
              fullWidth
              error={!!errors.document1}
              helperText={errors.document1 ? errors.document1.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="document2"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Document 2"
              variant="outlined"
              fullWidth
              error={!!errors.document2}
              helperText={errors.document2 ? errors.document2.message : ""}
            />
          )}
        />
      </Grid>

      {/* Add more fields for other documents */}
    </Grid>
  );
};

export default DriverDocumentForm;

// import React from "react";
// import { Form, Row, Col } from "react-bootstrap";

// const DocumentForm = ({ register, errors }) => {
//   return (
//     <Row className="mb-3">
//       <Col md={6}>
//         <Form.Group controlId="document1">
//           <Form.Label>Document 1</Form.Label>
//           <Form.Control
//             type="file"
//             {...register("document1")}
//             isInvalid={!!errors.document1}
//           />
//           <Form.Control.Feedback type="invalid">
//             {errors.document1?.message}
//           </Form.Control.Feedback>
//         </Form.Group>
//       </Col>

//       <Col md={6}>
//         <Form.Group controlId="document2">
//           <Form.Label>Document 2</Form.Label>
//           <Form.Control
//             type="file"
//             {...register("document2")}
//             isInvalid={!!errors.document2}
//           />
//           <Form.Control.Feedback type="invalid">
//             {errors.document2?.message}
//           </Form.Control.Feedback>
//         </Form.Group>
//       </Col>

//       {/* Add more document fields if needed */}
//     </Row>
//   );
// };

// export default DocumentForm;
