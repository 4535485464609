import React from "react";

import bgcity from "../../assets/Images/City.png";
import carimg from "../../assets/Images/EV_Colour 1.png";

function CarTypeInfo({ title, description }) {
  return (
    <>
      <h3 className="display-6 fw-bold">{title}</h3>
      <p className="text-muted fs-5 mt-3">{description}</p>
    </>
  );
}

function FeatureItem({ text }) {
  return <div className="text-center flex-grow-1">{text}</div>;
}

const features = [
  { text: "Reliable Pricing" },
  { text: "Zero Driver Cancellations" },
  { text: "Zero Emissions" },
];

const carTypeInfo = {
  title: (
    <>
      <span className="text-primary">Mini Cars</span> - for City Rides
    </>
  ),
  description:
    "Seamless point-to-point pickups, making urban travel smart, efficient, and eco-friendly",
  specs: (
    <ul className="mt-4 list-dotted text-start fw-bold">
      <li className="mb-2">4 Passengers</li>
      <li className="mb-2">Express Rides</li>
      <li className="mb-2">Tata Indica, Nissan Micra, Maruthi Ritz</li>
    </ul>
  ),
  //   "4 Passengers\nExpress Rides\nTata Indica, Nissan Micra, Maruthi Ritz",
};

function FleetSection() {
  return (
    <section className="text-center container py-5">
      <h2 className="display-4 fw-bold">Our Fleet</h2>
      <p className="text-muted fs-5 mt-2">
        We offer latest, comfort, and luxurious vehicles
      </p>
      <div className="bg-light rounded mt-5 p-4">
        <div className="bg-secondary text-white rounded-top p-4">
          <div className="d-flex justify-content-center flex-wrap gap-4">
            {features.map((feature, index) => (
              <React.Fragment key={index}>
                <FeatureItem text={feature.text} />
                {index < features.length - 1 && <div className="vr" />}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="d-flex flex-column align-items-start mt-5">
          <CarTypeInfo {...carTypeInfo} />
        </div>
        {/* <div className="d-flex flex-column align-items-start mt-5"> */}

        {/* <div className="d-flex justify-content-between align-items-start flex-wrap mt-3 w-100"> */}
        <div className="row">
          <div className="col-md-6">
            <div>
              <p className="fs-5">{carTypeInfo.specs}</p>
              <button className="btn btn-dark mt-4">Know More</button>
            </div>
          </div>
          <div
            className="col-md-6 d-flex align-items-center justify-content-center"
            style={{
              backgroundImage: `url(${bgcity})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "100%",
            }}
          >
            <img src={carimg} className="img-fluid" alt="Car" />
          </div>

          {/* <div className="col-md-6" style={{ backgroundImage: URL(bgcity) }}> */}
          {/* <img src={bgcity} className="img-fluid" /> */}
          {/* 
            <img src={carimg} />
          </div> */}
        </div>

        {/* <div
              className="w-100 mt-4"
              style={{ height: "287px", backgroundColor: "#f1f5f8" }}
            ></div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </section>
  );
}

export default FleetSection;
