import React from "react";

const TestimonialSection = () => {
  return (
    <section className="py-5 bg-light text-center">
      <div className="container">
        <p className="text-secondary">It's not a secret anymore</p>
        <header className="mb-4">
          <h2 className="display-5">What People Say About Us</h2>
          <button className="btn btn-dark mt-3">View all Reviews</button>
        </header>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 mb-4">
            <TestimonialCard />
          </div>
          <div className="col-lg-4 col-md-6 mb-4">
            <TestimonialCard />
          </div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d15b15967524703d69d2d09d624ba13a394835e190539cf55024e4a9964c43c4?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836"
          alt="Company logo"
          className="mt-5"
          style={{ width: "110px" }}
        />
      </div>
    </section>
  );
};

export default TestimonialSection;

const TestimonialCard = () => {
  return (
    <article className="card border-0 bg-light p-4">
      <div className="d-flex align-items-center mb-4">
        <div className="me-3">
          <div
            className="rounded-circle bg-primary text-white d-flex justify-content-center align-items-center"
            style={{ width: "100px", height: "100px", fontSize: "42px" }}
          >
            KJ
          </div>
        </div>
        <div>
          <h3 className="h5 mb-1">Kin Jhones</h3>
          <p className="text-muted mb-0">Chennai, Tamil Nadu</p>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6f9482fe24015781634df72f97c15383483c1ccb38e44c168bd357fc86b9c890?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836"
            alt="5 star rating"
            className="mt-2"
            style={{ width: "100px" }}
          />
        </div>
      </div>
      <p className="text-muted">
        Lorem ipsum dolor sit amet consectetur. Sed cum id varius tellus non.
        Vel volutpat tortor suspendisse hendrerit fermentum. Convallis accumsan
        risus sed id diam facilisis enim. Dolor eu eget iaculis pulvinar.
        Pulvinar sagittis diam pretium sapien ut feugiat nam. Donec nulla
        malesuada odio donec sed.
        <br />
        <br />
        Pharetra ante odio integer feugiat pellentesque ut fermentum nisl. Nec
        nisl lectus facilisi a aliquet netus. Proin malesuada turpis rhoncus
        aenean cursus augue. Ante turpis semper ut ultrices tempor est massa ut
        amet. Fermentum sit in aliquam aliquam ut volutpat. Suscipit a
        pellentesque etiam sit parturient urna non dui nec.
      </p>
    </article>
  );
};
