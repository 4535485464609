import React from "react";

const Footer = () => {
  return (
    <footer className="bg-light text-dark pt-5 pb-3 mt-5">
      <div className="container">
        <div className="row">
          {/* Logo and Description */}
          <div className="col-md-4 mb-4 text-center text-md-start">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/790a0ef43c451eb4d2c93da337f2e05742e4bea440847ff7dc7970b279dac707?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
              alt="Ride24 Logo"
              style={{ height: "50px" }}
              className="mb-3"
            />
            <p className="text-muted mt-4">
              We provide the best taxi services for you all over Tamil Nadu.
            </p>
            <div className="d-flex justify-content-center justify-content-md-start gap-2 mb-3">
              <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/222ba9c5b48c76938aab48e0be9fe4dbeedb3deaf71e67556597d347c4d0d425?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
                alt="App Store"
                className="img-fluid"
                // style={{ height: "200px" }}
              />
              {/* <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f9c68c5ed85e18756c28bcbb35cb8d94f0de5527831d1d9fb3a86619cba4e12?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
                alt="Google Play"
                style={{ height: "200px" }}
              /> */}
            </div>
            {/* <div className="d-flex justify-content-center justify-content-md-start gap-2"> */}
            {/* <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/222ba9c5b48c76938aab48e0be9fe4dbeedb3deaf71e67556597d347c4d0d425?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
                alt="App Store"
                style={{ height: "200px" }}
              /> */}
            {/* <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/qr-code-example1"
                alt="QR Code 1"
                style={{ height: "100px" }}
              /> */}
            {/* <img
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/qr-code-example2"
                alt="QR Code 2"
                style={{ height: "100px" }}
              /> */}
            {/* </div> */}
          </div>

          {/* Navigation Links */}
          <div className="col-md-4 mb-4 mt-5">
            <div className="row">
              <div className="col-6 mt-1">
                <h6 className="mb-4">Quick Links</h6>
                <ul className="list-unstyled ">
                  <li className="mb-3">
                    <a href="#home" className="text-dark text-decoration-none">
                      Ride with us
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#home" className="text-dark text-decoration-none">
                      Apply to Drive
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#home" className="text-dark text-decoration-none">
                      Outstation
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#home" className="text-dark text-decoration-none">
                      Rental
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#home" className="text-dark text-decoration-none">
                      Airport Rides
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#home" className="text-dark text-decoration-none">
                      One-way Trips
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#home" className="text-dark text-decoration-none">
                      Charges
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-6">
                <h6 className="mb-4">Company</h6>
                <ul className="list-unstyled">
                  <li className="mb-3">
                    <a href="#home" className="text-dark text-decoration-none ">
                      About Us
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#home" className="text-dark text-decoration-none">
                      Contact Us
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#home" className="text-dark text-decoration-none">
                      Careers
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#home" className="text-dark text-decoration-none">
                      Blogs
                    </a>
                  </li>
                  <li className="mb-3">
                    <a href="#home" className="text-dark text-decoration-none">
                      News & Events
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Contact and Social Media */}
          <div className="col-md-4 mb-5 text-center text-md-start mt-5">
            <h6 className="mb-4">Contact</h6>
            <p>hello@ride24.com for any media queries</p>
            <p>Customer Support</p>
            <p>---------------------------------------</p>
            <h6 className="mt-4">Ride24 Community</h6>
            <div className="d-flex justify-content-center justify-content-md-start gap-2 mt-4">
              <a href="#home" aria-label="Facebook">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/5f58685e0528dfe5c4ab151904de3fed02320396ccfbad4584e6dd7487ce312b?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
                  alt="Facebook"
                  style={{ height: "30px" }}
                />
              </a>
              <a href="#home" aria-label="Twitter">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f9c68c5ed85e18756c28bcbb35cb8d94f0de5527831d1d9fb3a86619cba4e12?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
                  alt="Twitter"
                  style={{ height: "30px" }}
                />
              </a>
              <a href="#home" aria-label="Instagram">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9524817a194e0e72b95e9b7f5efd46afdcea24c5e97a6a4c1327d2b37f3a5c70?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
                  alt="Instagram"
                  style={{ height: "30px" }}
                />
              </a>
              <a href="#home" aria-label="LinkedIn">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dcb02ddc25fee41d768237c632a4bfb6eca8f6dbfef5c29619f4a4c02208a8d?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
                  alt="LinkedIn"
                  style={{ height: "30px" }}
                />
              </a>
              <a href="#home" aria-label="Twitter">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f9c68c5ed85e18756c28bcbb35cb8d94f0de5527831d1d9fb3a86619cba4e12?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
                  alt="Twitter"
                  style={{ height: "30px" }}
                />
              </a>
              <a href="#home" aria-label="Instagram">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/9524817a194e0e72b95e9b7f5efd46afdcea24c5e97a6a4c1327d2b37f3a5c70?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
                  alt="Instagram"
                  style={{ height: "30px" }}
                />
              </a>
              <a href="#home" aria-label="LinkedIn">
                <img
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dcb02ddc25fee41d768237c632a4bfb6eca8f6dbfef5c29619f4a4c02208a8d?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
                  alt="LinkedIn"
                  style={{ height: "30px" }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="text-center pt-3 border-top">
          <p className="mb-0">
            Copyright &copy; 2023 All Rights Reserved by Ride24
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
