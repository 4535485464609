import React from "react";

const blogData = [
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/22fb9a2b4c21fadc7a4b837b3864aab3cc41c60642866558626607d0853f2780?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836",
    category: "BLOGS",
    date: "07 January, 2022",
    readTime: "3 min read",
    title: "Supercharge the Way You Render Large Lists in React",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/3f8a70a01141215cbbbc91bb04ba216bcfe5734bb56d3f984797e8892203c1eb?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836",
    category: "BLOGS",
    date: "07 January, 2022",
    readTime: "3 min read",
    title: "The Story Behind Dark Mode on the Android Ride24 App",
  },
  {
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/52e526e5dc0977387b4f032b7cc3664a20d534a8dddaadd61cd1a2bb4c438ef5?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836",
    category: "BLOGS",
    date: "07 January, 2022",
    readTime: "3 min read",
    title: "Use Passkeys Wherever You Sign in to Uber",
  },
];

function BlogSection() {
  function BlogHeader() {
    return (
      <>
        <h2 className="text-center text-secondary mb-3">
          It's not a secret anymore
        </h2>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
          <h1 className="display-4 fw-bold mb-3 mb-md-0">
            Recent from our blogs
          </h1>
          <a href="#" className="btn btn-dark">
            View all Blogs
          </a>
        </div>
      </>
    );
  }

  function BlogCard({ image, category, date, readTime, title }) {
    return (
      <article className="card h-100">
        <img loading="lazy" src={image} alt={title} className="card-img-top" />
        <div className="card-body">
          <p className="card-text text-muted">
            <strong>{category}</strong> | {date} | {readTime}
          </p>
          <h3 className="card-title">{title}</h3>
          <a href="#" className="text-primary">
            Know More
          </a>
        </div>
      </article>
    );
  }

  return (
    <section className="container py-5">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="w-100">
          <BlogHeader />
          <div className="mt-4">
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  {blogData.slice(0, 2).map((blog, index) => (
                    <div className="col-md-6 mb-4" key={index}>
                      <BlogCard {...blog} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-4">
                <BlogCard {...blogData[2]} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogSection;
