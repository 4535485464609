import React from "react";
import sec2img from "../../assets/Images/section2img.jpg";

const Section1 = () => {
  return (
    <section
      className="position-relative d-flex flex-column align-items-center text-center p-5"
      style={{ minHeight: "400px", marginTop: "40px" }}
    >
      <img
        loading="lazy"
        src={sec2img}
        className="position-absolute w-100 h-100 top-0 start-0"
        style={{ objectFit: "cover", objectPosition: "center" }}
        alt=""
      />
      <div className="position-relative"></div>
    </section>
  );
};

export default Section1;
