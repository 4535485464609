import React, { useEffect } from "react";

const Header = () => {
  useEffect(() => {
    document.body.setAttribute("data-bs-spy", "scroll");
    document.body.setAttribute("data-bs-target", "#navbarNav");
    document.body.setAttribute("data-bs-offset", "70");
    document.body.classList.add("scrollspy-example");
  }, []);

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand" href="#home">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/afa510274d34ced13ef0ea8b8554ee3f0566f41480862431b1beedfc2be5dc46?placeholderIfAbsent=true&apiKey=84b009ad8b6547158faecb97f6139d5d"
              alt="Ride24 Logo"
              className="d-inline-block align-text-top"
              style={{ height: "40px" }}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#ride">
                  Ride With Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#drive">
                  Drive With Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#blogs">
                  Blogs
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#news">
                  News & Events
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  About Us
                </a>
              </li>
            </ul>
            <button className="btn btn-dark ms-lg-3" type="button">
              Customer Care
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
