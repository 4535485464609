import React from "react";
import { Stepper, Step, StepLabel, Box } from "@mui/material";

const ProgressStepper = ({ activeStep, steps }) => {
  // Calculate progress percentage
  const progressPercentage = ((activeStep + 1) / steps.length) * 100;

  return (
    <div className="mb-4">
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index} completed={activeStep > index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* <div className="progress my-3">
          <div
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${progressPercentage}%`,
              backgroundColor: "#4caf50",
            }}
            aria-valuenow={progressPercentage}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {progressPercentage.toFixed(0)}% 
          </div>
        </div> */}
      </Box>
    </div>
  );
};

export default ProgressStepper;
