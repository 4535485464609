import React from "react";

const features = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/ab8b01d8883cfbe5223c033d380d16813cfe8e0e98dc949543fe6ca0e235d203?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836",
    title: "Affordable",
    description: "Competitive Prices",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5242ad9e37651beafdb0da317b59ffb8a1aaf6f4029f8870529dc5d313969f46?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836",
    title: "Reliable",
    description: "At Your Service 24/7",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5242ad9e37651beafdb0da317b59ffb8a1aaf6f4029f8870529dc5d313969f46?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836",
    title: "Dependable",
    description: "Arrive On Time",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/5242ad9e37651beafdb0da317b59ffb8a1aaf6f4029f8870529dc5d313969f46?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836",
    title: "Best Drivers",
    description: "New Standard of Comfort",
  },
];

export const HeroSection = () => {
  return (
    <section
      className="position-relative d-flex flex-column align-items-center text-center p-5"
      style={{ minHeight: "930px", marginTop: "40px" }}
    >
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7bb1b63de851083954b43fdb2e69ef83985861e8642c1fd9e58488c3e192c501?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836"
        className="position-absolute w-100 h-100 top-0 start-0"
        style={{ objectFit: "cover", objectPosition: "center" }}
        alt=""
      />
      <div className="position-relative">
        <h1 className="display-3 fw-bold text-dark">
          <span className="fw-light text-muted">The Most </span>
          Reliable
          <span className="fw-light text-muted"> Cab Booking!</span>
        </h1>
        <p className="mt-3 text-muted">
          Lorem ipsum dolor sit amet consectetur. Tellus scelerisque neque quis
          dolor. Commodo pretium ullamcorper eu integer nunc felis suscipit
          quis.
        </p>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4313c597f7b7a6a3307b8fa584cc7b1547d24743893afbe60d9a3f0c779da78?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836"
          className="mt-4 img-fluid"
          alt="Download on App Store and Google Play"
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/89595e6e1c655f2310fed5c1e22695e92029d5b7961ac3c51450687aca2007c8?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836"
          className="mt-5 img-fluid"
          alt="Cab booking app interface"
        />
      </div>
      <section className="container d-flex flex-wrap justify-content-evenly mt-5">
        {features.map((feature, index) => (
          <React.Fragment key={feature.title}>
            {index > 0 && <div className="border-end mx-4"></div>}
            <FeatureItem {...feature} />
          </React.Fragment>
        ))}
      </section>
    </section>
  );
};

function FeatureItem({ icon, title, description }) {
  return (
    <div className="text-center">
      <div className="d-flex align-items-center">
        <img
          loading="lazy"
          src={icon}
          className="img-fluid me-2"
          style={{ width: "25px" }}
          alt=""
        />
        <span className="fw-bold">{title}</span>
      </div>
      <p className="text-muted mt-2">{description}</p>
    </div>
  );
}

//  code for image background content

// const LaunchingComponent = () => {
//   const textItems = ["SO", "ON"];

//   return (
//     <section
//       className="d-flex flex-column justify-content-center align-items-center text-center position-relative p-5"
//       style={{
//         backgroundImage: 'url("https://path-to-your-car-image.jpg")',
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         minHeight: "100vh",
//       }}
//     >
//       <div className="d-flex flex-column">
//         <h1
//           className="display-1 fw-bold text-dark"
//           style={{ fontSize: "200px" }}
//         >
//           LAUNCHING
//         </h1>
//         <div
//           className="d-flex justify-content-around mt-4"
//           style={{ gap: "20px", fontSize: "180px" }}
//         >
//           {textItems.map((item, index) => (
//             <span key={index} className="fw-bold">
//               {item}
//             </span>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default LaunchingComponent;
