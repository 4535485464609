import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const HeroEndSection = () => {
  return (
    <section className="">
      <div className="row">
        <div className="col-md-12">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/67d4bbc831ea6454605b3bb896dba9cba1e90c5f364ee24bee4a8da82e02214e?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836"
            alt="Hero background"
            className="position w-100 h-100 object-fit-cover object-position-"
            loading="lazy"
          />
        </div>
        {/* <div className="col-md-4 position-absolute"></div>
        <div className="col-md-8 position-absolute"> */}
        <div
          className="position-absolute d-flex flex-column align-items-center justify-content-center text-center text-lg- "
          style={{
            background: "rgba(0, 0, 0, 0.6)",
            width: "100%",
            maxWidth: "500px",
            borderRadius: "10px",
            color: "white",
          }}
        >
          <h2 className="fw-bold">-50% ON FIRST RIDE</h2>
          <p className="mb-2">Our first-time customers get a great</p>
          <p className="mb-2">discount on their booking</p>
          <a href="/book-ride" className="btn btn-light">
            Book a Ride Now
          </a>
        </div>
        {/* </div> */}
        {/* <div className="position-absolute "></div> */}
      </div>
    </section>
    // <section className="">
    //   <div className="row">
    //     <div className="position-relative d-flex flex-column align-items-end w-100 min-vh-50 py-5 ">
    // <img
    //   src="https://cdn.builder.io/api/v1/image/assets/TEMP/67d4bbc831ea6454605b3bb896dba9cba1e90c5f364ee24bee4a8da82e02214e?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836"
    //   alt="Hero background"
    //   className="position-absolute w-100 h-100 object-fit-cover object-position-center"
    //   loading="lazy"
    // />
    //       <div className="position-relative d-flex flex-column align-items-center justify-content-center w-100 px-3">
    //         {/* <img
    //         src="https://cdn.builder.io/api/v1/image/assets/TEMP/2841d8953c6df037289106ec896fb8cc07c6c3e133de2eec5c8c6c6eb1639da4?placeholderIfAbsent=true&apiKey=f206b2755d2544b19e325fa3b887c836"
    //         alt="Featured content"
    //         className="img-fluid rounded shadow-sm"
    //         style={{
    //           maxWidth: "561px",
    //           aspectRatio: "2.67",
    //           marginLeft: "106px",
    //         }}
    //         loading="lazy"
    //       /> */}
    // <div
    //   className="d-flex flex-column align-items-center justify-content-center text-center text-lg-start "
    //   style={{
    //     background: "rgba(0, 0, 0, 0.6)",
    //     width: "100%",
    //     maxWidth: "500px",
    //     borderRadius: "10px",
    //     color: "white",
    //   }}
    // >
    //   <h2 className="fw-bold">-50% ON FIRST RIDE</h2>
    //   <p className="mb-2">Our first-time customers get a great</p>
    //   <p className="mb-2">discount on their booking</p>
    //   <a href="/book-ride" className="btn btn-light">
    //     Book a Ride Now
    //   </a>
    // </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default HeroEndSection;
