import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const faqData = [
  {
    question: "How do I create an account?",
    answer:
      "Download the Ride24 app from the App Store or Google Play, then create an account with your email address and mobile phone number. A payment method is also needed before you can request a ride.",
    bulletPoints: ["How to create an account"],
  },
  {
    question:
      "Is it possible to get the same Driver-Partner assigned for all my trips?",
    answer:
      "Currently, Ride24 does not support assigning the same driver-partner for all your trips. However, you can choose from the available driver-partners at the time of booking.",
  },
  {
    question: "How do I request a ride?",
    answer:
      "Open the Ride24 app, select your destination, choose your preferred ride option, and confirm your pickup location. Tap 'Request' to find a nearby driver.",
  },
  {
    question: "Can I use Ride24 without a smartphone?",
    answer:
      "Yes, you can book a Ride24 service through our website or by calling our customer service number. A registered account is required for booking.",
  },
];

// Function to sanitize the ID
function sanitizeId(str) {
  return str.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();
}

function FAQPage() {
  return (
    <div className="container py-5">
      <h3 className="text-secondary mb-3">Unlocking Knowledge with Our FAQs</h3>
      <h2 className="text-center fw-bold display-4 mb-5">
        Frequently Asked Questions
      </h2>
      <div className="row">
        <aside className="col-md-4 mb-4">
          <div>
            <h4 className="fw-semibold">Rider FAQs</h4>
            <hr className="my-3" />
            <h4 className="fw-semibold text-muted">Driver FAQs</h4>
          </div>
        </aside>
        <main className="col-md-8">
          <div className="accordion" id="faqAccordion">
            {faqData.map((faq, index) => {
              const sanitizedId = sanitizeId(faq.question);
              return (
                <div className="accordion-item mb-3" key={index}>
                  <h2
                    className="accordion-header"
                    id={`heading-${sanitizedId}`}
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${sanitizedId}`}
                      aria-expanded="false"
                      aria-controls={`collapse-${sanitizedId}`}
                    >
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse-${sanitizedId}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${sanitizedId}`}
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      {faq.answer && (
                        <p className="text-muted">
                          {faq.answer}
                          {faq.bulletPoints && (
                            <ul className="mt-2">
                              {faq.bulletPoints.map((point, index) => (
                                <li key={index}>{point}</li>
                              ))}
                            </ul>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </main>
      </div>
    </div>
  );
}

export default FAQPage;
