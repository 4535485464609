import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const newsData = [
  {
    meta: "NEWS | 07 January, 2022 | 3 min read",
    title: "Secures 2nd Place in Fairwork India Annual Ratings 2023",
    excerpt:
      "Lorem ipsum dolor sit amet consectetur. Praesent platea quam varius imperdiet.",
    hasImage: true,
    hasDivider: false,
  },
  {
    meta: "NEWS | 07 January, 2022 | 3 min read",
    title: "Ride24 Unveils Its New Visual Identity",
    excerpt:
      "Lorem ipsum dolor sit amet consectetur. Praesent platea quam varius imperdiet.",
    hasImage: false,
    hasDivider: true,
  },
  {
    meta: "NEWS | 07 January, 2022 | 3 min read",
    title: "Ride24 Celebrates 10k Electric Rides Milestone in Tamil Nadu",
    excerpt: "Lorem ipsum dolor sit amet consectetur.",
    hasImage: false,
    hasDivider: true,
  },
];

const NewsCard = ({ meta, title, excerpt, hasImage, hasDivider }) => {
  return (
    <div className="card mb-4">
      {hasImage && (
        <div className="card-img-top bg-dark" style={{ height: "200px" }} />
      )}
      <div className="card-body">
        <p className="text-muted">{meta}</p>
        <h5 className="card-title">{title}</h5>
        <p className="card-text">
          {excerpt} <span className="text-primary">Read More</span>
        </p>
        {hasDivider && <hr className="my-4" />}
      </div>
    </div>
  );
};

const NewsAndEvents = () => {
  return (
    <section className="container my-5">
      <h2 className="text-center mb-3">
        News and Events of Ride <span className="text-primary">24</span>
      </h2>
      <p className="text-center text-muted mb-5">
        Discover the Stories Shaping the Future of Mobility
      </p>
      <div className="row">
        <div className="col-lg-8">
          <NewsCard {...newsData[0]} />
        </div>
        <div className="col-lg-4">
          {newsData.slice(1).map((news, index) => (
            <NewsCard key={index} {...news} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsAndEvents;
