import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./Pages/Main/Landing";
import Onboarding from "./Pages/Main/Onboarding";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/form" element={<Onboarding />} />{" "}
          {/* Ensure you create this component */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
